@import url(https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: "Balsamiq Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.Home_root__2lrj7 {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.Home_title__2wfN0 {
    color: white;
    font-size: 1.8em;
    margin-bottom: 15px;
}

.Home_blocks__3QVaT {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
}

.Home_block__2zoDS {
    margin-right: 15px;
}

@media only screen and (max-width: 1020px) {
    .Home_blocks__3QVaT {
        flex-direction: column;
    }
    .Home_block__2zoDS {
        margin-bottom: 15px;
    }
}


.Block_root__2p1V5 {
    padding: 50px;
    height: 70px;
    width: 140px;
    box-shadow: 0px 5px 33px -13px rgba(0,0,0,0.5);
    background: rgba(211, 211, 211, 0.349);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}

.Block_numbers__30CUj {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
}

.Block_title__i8hBO {
    font-size: 30px;
    color: white;
}


.NumberDisplay_value__3sBmX {
    position: relative;
    height: 100px;
    width: 70px;
    font-size: 100px;
    overflow: hidden;
}

.NumberDisplay_numbercontainer__37ZEF {
    position: relative;
    height: 100%;
    width: 100%;
    transition: all 800ms;
}

.NumberDisplay_number__2RARv {
    color: white;
    font-weight: bold;
    position: absolute;
    left: 0px;
    right: 0px;
    height: 50px;
}
